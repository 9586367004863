import { warehouse } from './api/warehouse'
import { performanceReview } from '~/features/human-resources/performance-reviews/api'
import { documents } from './api/documents'
import productApiFactory from '~/features/product/api'

/**
 * Moved to features folder
 */
// Auth
import auth from '~/features/auth/api'

// Auth Image
import { authImageApiFactory } from '~/components/widgets/auth-img/api'

// Admin
import { adminApiFactory } from '~/features/admin/api'

// Service Desk
import { serviceDeskApiFactory } from '~/features/service-desk/api'

// AI
import { AIApiFactory } from '~/features/chatbot/api'

// Catalog
import { catalogAPIFactory } from '~/features/catalog/api'
import { catalogProductDetailsFactory } from '~/features/catalog/product-details/api'
import { catalogProductComparisonApiFactory } from '~/features/catalog/product-comparison/api'
import { catalogProductPositionsApiFactory } from '~/features/catalog/product-positions/api'
import { catalogProductSpecsFactory } from '~/features/catalog/product-specs/api'

// Content
import { contentGearzetteApiFactory } from '~/features/content/gearzette/api'
import { contentLandingPagesApiFactory } from '~/features/content/landing-pages/api'

// Subscribers
import { subscribersApiFactory } from '~/features/events/api'

// Customers
import { customersApiFactory } from '~/features/customers/api'
import { customersPriceListApiFactory } from '~/features/customers/price-list/api'

// Factory
import { factoryAPIFactory } from '~/features/factory/api'
import { factoryLabelsFactoryLabelsAPIFactory } from '~/features/factory/labels/api'

// Documents
import { documentsLostAndFoundPodApiFactory } from '~/features/documents/lost-and-found-pod/api'

// Human Resources
import { orgChartApiFactory } from '~/features/human-resources/org-chart/api'
import { HumanResourcesVacanciesApiFactory } from '~/features/human-resources/vacancies/api'

// Vfits
import { vfitsApiFactory } from '~/features/vfits/api'

// Sales
import { salesAffiliateApiFactory } from '~/features/sales/affiliates/api'
import { salesGiftcardApiFactory } from '~/features/sales/giftcards/api'
import { salesOrdersApiFactory } from '~/features/sales/orders/api'
import { salesInvoicesApiFactory } from '~/features/sales/invoices/api'
import { salesGraphsApiFactory } from '~/features/sales/sales-graphs/api'
import { salesBlackFridayApiFactory } from '~/features/sales/black-friday/api'
import { salesPointOfSaleApiFactory } from '~/features/sales/point-of-sale/api'

// Warehouse
import { warehouseShippingDashboardApiFactory } from '~/features/warehouse/shipping-dashboard/api'
import { warehouseComponentLabelsAPIFactory } from '~/features/warehouse/labels/component-labels/api'
import { warehouseOuterLabelsAPIFactory } from '~/features/warehouse/labels/outer-labels/api'
import { warehouseProductLabelsAPIFactory } from '~/features/warehouse/labels/product-labels/api'
import { warehouseQRLabelsAPIFactory } from '~/features/warehouse/labels/qr-labels/api'
import { warehouseBinLabelsAPIFactory } from '~/features/warehouse/labels/bin-labels/api'
import { warehouseShippingDetailsApiFactory } from '~/features/warehouse/shipping-details/api'

// Countries
import { countriesApiFactory } from '~/features/country/api'

export default {
  auth,
  admin: adminApiFactory,
  serviceDesk: serviceDeskApiFactory,
  AI: AIApiFactory,
  authImage: authImageApiFactory,
  catalog: {
    ...catalogAPIFactory,
    productDetails: catalogProductDetailsFactory,
    productComparison: catalogProductComparisonApiFactory,
    productPositions: catalogProductPositionsApiFactory,
    productSpecs: catalogProductSpecsFactory
  },
  country: countriesApiFactory,
  sales: {
    giftcard: salesGiftcardApiFactory,
    affiliates: salesAffiliateApiFactory,
    orders: salesOrdersApiFactory,
    invoices: salesInvoicesApiFactory,
    graphs: salesGraphsApiFactory,
    blackfriday: salesBlackFridayApiFactory,
    pointOfSale: salesPointOfSaleApiFactory
  },
  vfits: vfitsApiFactory,
  subscribers: subscribersApiFactory,
  customers: {
    ...customersApiFactory,
    priceList: customersPriceListApiFactory
  },
  warehouse: {
    ...warehouse,
    shippingDashboard: warehouseShippingDashboardApiFactory,
    labels: {
      bineLabels: warehouseBinLabelsAPIFactory,
      componentLabels: warehouseComponentLabelsAPIFactory,
      outerLabels: warehouseOuterLabelsAPIFactory,
      productLabels: warehouseProductLabelsAPIFactory,
      QRLabels: warehouseQRLabelsAPIFactory
    },
    shippingDetails: warehouseShippingDetailsApiFactory
  },
  factory: {
    ...factoryAPIFactory,
    labels: {
      factoryLabels: factoryLabelsFactoryLabelsAPIFactory
    }
  },
  humanResources: {
    vacancies: HumanResourcesVacanciesApiFactory
  },
  performanceReview,
  documents: {
    ...documents,
    LostAndFound: documentsLostAndFoundPodApiFactory
  },
  content: {
    gearzette: contentGearzetteApiFactory,
    landingPages: contentLandingPagesApiFactory
  },
  product: {
    ...productApiFactory
  },
  organizationalChart: orgChartApiFactory
}
